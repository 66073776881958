import { toast } from 'material-react-toastify';
import { Component } from 'react'
import Sheet from 'react-modal-sheet';
import AuthApis from '../helper/api.service';
import Terms from './Terms';

export default class Login extends Component {
    constructor(props) {
        super(props);
        const queryParams = new URLSearchParams(window.location.search);
		global.ecid= queryParams.get('ecid')
			? queryParams.get('ecid')
			: null;
        this.state = {
            time: 60,
            OTP: '',
            mobileNumber: '',
            AAHandle: '',
            isResend: true,
            isOpenSheet: false,
            isOpenOTPSheet: false,
            disableType: '',
            verifySpinner: false,
            sendSpinner: false
        };
        this.number = null;
        this.authApis = new AuthApis()
    }
    componentDidMount() {
        window.finvuClient.openRevoke()
    }

    initiate = () => {
        this.setState((prevState, prevProps) => ({
            time: prevState.time - 1
        }));
        if (this.state.time === 0) {
            clearInterval(this.id);
            this.setState({ time: 60, isResend: true });
        }
    };

    sendOtp = () => {
        this.setState({ isResend: false, OTP: '', sendSpinner: true })
        this.id = setInterval(this.initiate, 1000);
        let aaHandle = this.state.AAHandle
        if (aaHandle) {
            aaHandle = aaHandle.replace("@finvu", ""); 
            aaHandle = `${aaHandle}@finvu`
        }
        this.authApis.userLogin(this.state.mobileNumber, aaHandle).then(res => {
            console.log("res", res);
            if (res && res?.status === 'SEND') {
                console.log(res.message)
                this.setState({ isOpenOTPSheet: true, sendSpinner: false })
            } else {
                this.setState({ sendSpinner: false })
                toast.error(res.message)
                console.log(res)
            }
        })

    }

    generateToast = (res) => {
        toast.success(`Welcome! Your AA handle is ${res.UserInfo.userId}`)
    }


    verifyOtp = () => {
        this.setState({ isResend: false, OTP: '', verifySpinner: true, time: 60 })
        clearInterval(this.id);
        this.authApis.verifyOtp(this.state.OTP).then(res => {
            console.log("res", res);
            this.setState({ verifySpinner: false, time: 60, isResend: true })
            if (res?.status && res?.status === 'SUCCESS') {
                console.log(res.message)
                this.authApis.getUserDetails(this.generateToast)
                this.props.history.push({
                    pathname: `/revoke-consent`,
                    state: {
                         number: this.state.mobileNumber
                    }
                });
            } else {
                toast.error(res)
                console.log(res)
            }
        })

    }


    render() {
        return (
            <div className='login-page mt-9'>
                <div className='container px-3'>
                    <div className='row' style={{ minHeight: '68vh' }}>
                        <div className='col-lg-6 offset-lg-3 col-12 mt-5'>
                            <div className='row '>
                                <div className='col-lg-12 col-12'>
                                    <h1 className='fs-14 fw-600 text-start' style={{ color: this.props.customDetails?.headingColor, fontFamily: this.props.customDetails?.fontFamily }}>REVOKE CONSENT</h1>
                                    <p className="fs-14 fw-500 text-start mt-4" style={{ color: this.props.customDetails?.textColor, fontFamily: this.props.customDetails?.fontFamily }}>Enter your registered mobile number or AA handle to verify and revoke your consents </p>
                                    <p className="fs-14 fw-500 text-start mt-4" style={{ color: this.props.customDetails?.textColor, fontFamily: this.props.customDetails?.fontFamily }}>OTP will be sent to your mobile number </p>
                                    {/* <p className='fs-14 fw-500 text-start' style={{ color: this.props.customDetails?.textColor, fontFamily: this.props.customDetails?.fontFamily }}>OTP sent to your registered mobile number<br /><span className='fs-16 fw-600' style={{ color: this.props.customDetails?.subHeadingColor, fontFamily: this.props.customDetails?.fontFamily }}> {this.props.match.params.number} </span></p> */}
                                </div>
                                <div className='col-12'>
                                    <div className="input-group mb-3" style={{ height: '50px' }}>
                                        <input type="number" inputMode="decimal" value={this.state.mobileNumber} onChange={(e) => { this.setState({ mobileNumber: e.target.value, AAHandle: '' }) }} className="form-control ps-3" placeholder="Enter Mobile number" aria-label="number" aria-describedby="basic-addon1" style={{ fontFamily: this.props.customDetails?.fontFamily }} />
                                    </div>

                                </div>
                                <div className='col-12'>
                                    <p style={{ color: this.props.customDetails?.textColor, fontFamily: this.props.customDetails?.fontFamily }}>OR</p>
                                </div>
                                <div className='col-12 mb-5'>
                                    <div className="input-group mb-3" style={{ height: '50px' }}>
                                        <input type="text" value={this.state.AAHandle} onChange={(e) => { this.setState({ AAHandle: e.target.value, mobileNumber: '' }) }} className="form-control ps-3" placeholder="Enter AA Handle" aria-label="number" aria-describedby="basic-addon1" style={{ fontFamily: this.props.customDetails?.fontFamily, paddingRight: '70px' }} />
                                        <span className='input-fix-finvu'>@finvu</span>
                                    </div>

                                </div>

                                <div className='col-lg-6 offset-lg-3 col-md-6 offset-md-3 col-12 d-flex justify-content-center'>
                                    <button type="button" disabled={!(this.state.mobileNumber?.length === 10) && !this.state.AAHandle} onClick={this.sendOtp} className="btn float-end px-5 fs-16 fw-600 text-white" style={{ height: this.props.customDetails?.btnHeight, width: this.props.customDetails?.btnWidth, borderRadius: this.props.customDetails?.borderRadius, backgroundColor: this.props.customDetails?.primaryColor, fontFamily: this.props.customDetails?.fontFamily }}>
                                        {
                                            (this.state.sendSpinner) ?
                                                <div className="snippet" style={{ display: 'flex', justifyContent: 'center' }} data-title=".dot-pulse">
                                                    <div className="stage">
                                                        <div className="dot-pulse-btn"></div>
                                                    </div>
                                                </div>
                                                :
                                                'Get OTP'
                                        }

                                    </button>
                                </div>

                            </div>
                        </div>

                    </div>
                    <div className="row">
                        <div className='col-lg-6 offset-lg-3 col-12'>
                            <p className='fs-12 fw-600' style={{ color: this.props.customDetails?.textColor, bottom: '0px', fontFamily: this.props.customDetails?.fontFamily }}> By continuing, you agree to Finvu <span className='' style={{ color: this.props.customDetails?.subHeadingColor }}><button disabled={this.state.sendSpinner} className='btn btn-link' onClick={() => { this.setState({ isOpenSheet: true }) }} style={{ fontFamily: this.props.customDetails?.fontFamily }}>Terms and Conditions </button></span>
                                <br />
                                <br />
                                Finvu is a brand of Cookiejar Technologies Pvt Ltd, a regulated and licensed NBFC AA by RBI
                            </p>
                        </div>
                    </div>
                </div>
                <Sheet isOpen={this.state.isOpenSheet} onClose={() => this.setState({ isOpenSheet: false })}>
                    <Sheet.Container>
                        <Sheet.Header>
                            <div style={{ textAlign: 'right' }}>
                                <button className='closeModal' onClick={() => this.setState({ isOpenSheet: false })}>X</button>
                            </div>
                        </Sheet.Header>
                        <Sheet.Content>
                            <Terms />
                        </Sheet.Content>
                    </Sheet.Container>

                    <Sheet.Backdrop />
                </Sheet>
                <Sheet snapPoints={[400]} disableDrag={true} isOpen={this.state.isOpenOTPSheet} onClose={() => this.setState({ isOpenOTPSheet: false })}>
                    <Sheet.Container>
                        <Sheet.Content>
                            <div className="row mx-4">
                                <div className="col-lg-4 offset-lg-4 col-md-8 offset-md-2 col-12 pt-4">
                                    {/* <p className="fs-16 fw-600 text-start" style={{ color: this.props.customDetails?.textColor, fontFamily: this.props.customDetails?.fontFamily }}>{this.state.selectedBank?.productName} has sent OTP for verification</p> */}
                                    <p className="fs-16 fw-600 text-start mt-4" style={{ color: this.props.customDetails?.textColor, fontFamily: this.props.customDetails?.fontFamily }}>Enter OTP sent to your mobile number <span style={{ color: this.props.customDetails?.subHeadingColor, fontFamily: this.props.customDetails?.fontFamily }}>{this.state.mobileNumber}</span></p>
                                </div>
                                <div className="col-lg-4 offset-lg-4 col-md-8 offset-md-2 col-12">
                                    <input type="number" inputMode="decimal" aria-label="OTP" className="form-control mb-2" value={this.state.OTP} onChange={(e) => { this.setState({ OTP: e.target.value }) }} style={{ height: '50px', fontFamily: this.props.customDetails?.fontFamily }}></input>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <button className='fs-14 fw-600 link-btn' disabled={!this.state.isResend} style={{ color: (this.state.isResend) ? this.props.customDetails?.subHeadingColor : 'grey' }} onClick={this.sendOtp}>Resend OTP</button>
                                        {
                                            this.state.time !== 60 ?
                                                <p className='fs-14 fw-600 text-success my-auto' style={{ fontFamily: this.props.customDetails?.fontFamily }}>in {this.state.time} secs</p>
                                                : null
                                        }
                                    </div>
                                </div>
                                {
                                    (window.navigator.appVersion.indexOf('Mac') !== -1) ?
                                        <div className="col-12 mt-3">
                                            <p className='fw-600 text-lg-start text-center' style={{ margin: 0 }}>Please DO NOT switch app while reading the OTP</p>
                                        </div> : null
                                }

                                <div className='col-lg-6 offset-lg-3 col-md-6 offset-md-3 col-12 d-flex justify-content-center mb-4'>
                                    <button type="button" disabled={!(this.state.OTP?.length === 6 || this.state.OTP?.length === 8) || this.state.verifySpinner} onClick={this.verifyOtp} className="btn float-end px-5 fs-16 fw-600 text-white mt-3" style={{ height: this.props.customDetails?.btnHeight, width: this.props.customDetails?.btnWidth, borderRadius: this.props.customDetails?.borderRadius, backgroundColor: this.props.customDetails?.primaryColor, fontFamily: this.props.customDetails?.fontFamily }}>
                                        {
                                            (this.state.verifySpinner) ?
                                                <div className="snippet" style={{ display: 'flex', justifyContent: 'center' }} data-title=".dot-pulse">
                                                    <div className="stage">
                                                        <div className="dot-pulse-btn"></div>
                                                    </div>
                                                </div>
                                                :
                                                'VERIFY'
                                        }
                                    </button>
                                </div>
                            </div>
                        </Sheet.Content>
                    </Sheet.Container>

                    <Sheet.Backdrop />
                </Sheet>
            </div>
        )
    }
}
