import { toast } from "material-react-toastify";

export default class AuthApis {

    async userLogin(number,AAHandle) {
        try {
            const res = await window.finvuClient.login(`none`, AAHandle, number)
            if (res) {
                return res;
            }
        } catch (e) {

        }
    }
    async verifyOtp(otp) {
        try {
            const res = await window.finvuClient.verifyOTP(otp)
            console.log("data  - ", res)
            if (res) {
                return res;
            }
        } catch (e) {

        }
    }
    async userActiveConsents() {
        try {
            const res = await window.finvuClient.userActiveConsents()
            console.log("consents - ", res)
            if (res) {
                return res;
            }
        } catch (e) {

        }
    }
    async revokeConsent(id) {
        try {
            const res = await window.finvuClient.revokeConsent(id)
            console.log("consents - ", res)
            if (res) {
                return res;
            }
        } catch (e) {

        }
    }
    async consentDetails(consentId) {
        try {
            const res = await window.finvuClient.consentDetails(consentId)
            console.log("consents - ", res)
            if (res) {
                return res;
            }
        } catch (e) {

        }
    }
    async entityInfo(id,type) {
        console.log("entity - ",type)
        try {
            const res = await window.finvuClient.entityInfo(id,type)
            if (res) {
                return res;
            }
        } catch (e) {

        }
    }
    getUserDetails(callback)  {
        try {
            const res = window.finvuClient.userDetails()
            if (res) {
                if (res?.status && res?.status === 'ACCEPT') {
                    callback(res)
                } else {
                    toast.error(res)
                    console.log(res)
                }
            }
        } catch (e) {

        }
    }
    async logout(userId) {
        try {
            const res = await window.finvuClient.logout(userId)
            if (res) {
                return res;
            }
        } catch (e) {

        }
    }
}